<template>
  <div class="task-add">
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules1" label-width="150px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="data package name" prop="deviceName">
                <el-input
                  v-model="postData.deviceName"
                  size="small"
                  placeholder="请输入数据包名称"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="数据包类型" prop="dataSourceType">
                <el-select
                  v-model="postData.dataSourceType"
                  size="small"
                  placeholder="Please select"
                >
                  <el-option
                    v-for="item in options.dataSourceType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="country" prop="country">
                <el-select
                  v-model="postData.country"
                  size="small"
                  placeholder="选择country"
                  filterable
                  class="w100"
                >
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :label="item.countryCode"
                    :value="item.countryCode"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="os" prop="os">
                <el-select
                  v-model="postData.os"
                  size="small"
                  placeholder="选择 Android or iOS"
                  v-on:change="getPromotionApp"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6" v-if="postData.dataSourceType === '0'">
              <el-form-item label="单位" prop="unit">
                <el-select
                  v-model="postData.unit"
                  size="small"
                  placeholder="Please select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options.unit"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6" v-if="postData.dataSourceType === '0'">
              <el-form-item label="Time" prop="time">
                <el-input v-model="postData.time" placeholder="请输入" size="small" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="os version ge" prop="osVersion">
                <el-input v-model="postData.osVersion" size="small" placeholder="大于等于10.1" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="os version le" prop="osVersionMax">
                <el-input v-model="postData.osVersionMax" size="small" placeholder="小于等于13.3" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="activation gt" prop="activation">
                <el-select
                  v-model="postData.activation"
                  size="small"
                  placeholder="大于"
                  :clearable="true"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.activation"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="promotion app" prop="promotionApp">
                <el-select
                  v-model="postData.promotionApp"
                  size="small"
                  placeholder="需要上量的offer的包名,数据包的数据会排除安转了此app的数据"
                  filterable
                  :clearable="true"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.promotionApp"
                    :key="item.prod"
                    :label="item.prod"
                    :value="item.prod"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-form-item>
              <el-col :span="6">
                <el-tag type="danger" class="w100"
                  >请注意：实时数据目前不支持DSP，实时数据只能选appsflyer，mb</el-tag
                >
              </el-col>
            </el-form-item>
          </el-row>

          <el-form-item label="traffic adx" prop="trafficAdx">
            <group-select
              v-model="postData.trafficAdx"
              :data-source="adxOptions"
              class="w100"
              multiple
              clearable
              reserve-keyword
              filterable
              size="small"
              placeholder="Please select adx"
            />
          </el-form-item>

          <el-form-item>
            <el-button @click="openAppCvrSearchDialog()">Traffic App Suggestion Check</el-button>
          </el-form-item>

          <el-form-item v-if="postData.dataSourceType === '0'">
            <el-switch
              v-model="postData.requireAppActive"
              active-text="app在活跃时间内"
              inactive-text=""
              active-value="yes"
              inactive-value="no"
            >
            </el-switch>
          </el-form-item>

          <el-form-item label="traffic apps" prop="trafficApps">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入需要的app包名，多个请用“英文”逗号隔开"
              v-model="postData.trafficApps"
            />
          </el-form-item>

          <el-form-item label="black traffic apps" prop="blackTrafficApps">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入需要屏蔽的app包名，多个请用“英文”逗号隔开"
              v-model="postData.blackTrafficApps"
            />
          </el-form-item>

          <el-row>
            <el-col :span="12">
              <el-form-item label="cat white" prop="catWhite">
                <el-select
                  v-model="postData.catWhite"
                  size="small"
                  placeholder="选择分类"
                  multiple
                  class="w100"
                >
                  <el-option
                    v-for="item in options.cat"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="con_event" prop="convEvent">
                <el-select
                  v-model="postData.convEvent"
                  size="small"
                  placeholder="选择事件"
                  multiple
                  filterable
                  allow-create
                  class="w100"
                >
                  <el-option
                    v-for="item in options.event"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6" v-if="postData.dataSourceType === '0'">
              <el-form-item label="traffic app count" prop="appCountRequire">
                <el-select
                  v-model="postData.appCountRequire"
                  size="small"
                  :clearable="true"
                  placeholder="Please select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options.appCount"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6" v-if="postData.dataSourceType === '0'">
              <el-form-item label="traffic source count" prop="sourceCountRequire">
                <el-select
                  v-model="postData.sourceCountRequire"
                  size="small"
                  :clearable="true"
                  placeholder="Please select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options.appCount"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6" v-if="postData.dataSourceType === '0'">
              <el-form-item label="ad type count" prop="adCountRequire">
                <el-select
                  v-model="postData.adCountRequire"
                  size="small"
                  :clearable="true"
                  placeholder="Please select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options.appCount"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="center" class="mb0">
            <el-button
              :loading="loading2.btn"
              size="small"
              type="success"
              @click="searchCount('postData')"
              >查询数量
            </el-button>
            <el-button :loading="loading2.btn" size="small" type="success" @click="add('postData')"
              >保存
            </el-button>
            <el-button :loading="loading2.btn" size="small" type="primary" @click="goOff()"
              >取消
            </el-button>
          </el-form-item>
        </el-form>

        <el-dialog
          title="查询app相关度"
          :visible.sync="appCvrSearchForm"
          width="70%"
          v-on:close="closeDialog()"
        >
          <el-form ref="filter" :model="filter" size="small" :rules="rules2">
            <el-row>
              <el-col :span="3">
                <el-form-item label="country" prop="country" label-width="80px">
                  <el-select
                    v-model="filter.country"
                    size="small"
                    placeholder="选择country"
                    filterable
                  >
                    <el-option
                      v-for="item in options.country"
                      :key="item.countryCode"
                      :label="item.countryCode"
                      :value="item.countryCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="3">
                <el-form-item label="os" prop="os" label-width="50px">
                  <el-select
                    v-model="filter.os"
                    size="small"
                    placeholder="Please select"
                    v-on:change="getPromotionApp2"
                  >
                    <el-option
                      v-for="item in options.os"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="promotion app" prop="promotionApp" label-width="130px">
                  <el-select
                    v-model="filter.promotionApp"
                    size="small"
                    placeholder="需要上量的offer的包名"
                    filterable
                  >
                    <el-option
                      v-for="item in options.promotionApp"
                      :key="item.prod"
                      :label="item.prod"
                      :value="item.prod"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="min device count:" prop="minDeviceCount" label-width="150px">
                  <el-input v-model="filter.minDeviceCount" class="mr10"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="3">
                <el-form-item label="exp cvr:" prop="expCvr" label-width="80px">
                  <el-input v-model="filter.expCvr" class="mr10"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-form-item label-width="20px">
                  <el-button
                    type="primary"
                    :loading="loading1.btnSearchAppReport"
                    size="mini"
                    v-on:click="searchAppReportData('filter')"
                    >search
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <el-table :data="appReportData">
            <el-table-column property="appId" label="traffic app"></el-table-column>
            <el-table-column property="deviceCount" label="device count"></el-table-column>
            <el-table-column property="expCvr" label="exp cvr"></el-table-column>
          </el-table>

          <div slot="footer" class="dialog-footer">
            <el-button v-on:click="cancelAppCheck()">Cancel</el-button>
            <el-button type="primary" v-on:click="applyCheckApp('filter')">Apply</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { fetchCountryList } from '@/api/DDJ/v3/country';
  import {
    fetchPromotionAppList,
    fetchAppReportData,
    addHoloUpload,
    searchCount,
    fetchAdxList1,
  } from 'api/DDJ/v3/dmpFilter';
  import optionData from '@/assets/js/optionData';
  import GroupSelect from '@/components/GroupSelect';

  export default {
    components: {
      GroupSelect,
    },
    data() {
      return {
        filter: {
          country: '',
          os: '',
          promotionApp: '',
          minDeviceCount: 0,
          expCvr: 0.0,
        },
        appReportData: [],
        appsStr: '',
        postData: {
          trafficApps: '',
          blackTrafficApps: '',
          country: '',
          minDeviceCount: 0,
          expCvr: 0.0,
          promotionApp: '',
          dataSourceType: '0',
          unit: 'day',
          time: 1,
          os: 'Android',
          osVersion: 10,
          requireAppActive: 'yes',
        },
        loading1: {
          btnSearchAppReport: false,
        },
        loading2: {
          btn: false,
        },
        options: {
          country: [],
          promotionApp: [],
          os: optionData.osOption,
          dataSourceType: [
            { label: '定时更新', value: '0' },
            { label: '实时', value: '1' },
          ],
          unit: [
            { label: 'day', value: 'day' },
            { label: 'hour', value: 'hour' },
            { label: 'minute', value: 'minute' },
          ],
          activation: optionData.activationOption,
          cat: optionData.catOptionHolo,
          event: optionData.conEventOption,
          appCount: [
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '5', value: 5 },
            { label: '8', value: 8 },
          ],
        },
        adxOptions: [],
        appCvrSearchForm: false,
        rules1: {
          deviceName: [{ required: true, message: '数据包名称不能为空', trigger: 'blur' }],
          country: [{ required: true, message: 'country不能位空', trigger: 'blur' }],
          os: [{ required: true, message: 'os不能为空', trigger: 'blur' }],
          time: [
            { required: true, message: 'day不能为空', trigger: 'blur' },
            { validator: this.validateIntegerValue, trigger: 'blur' },
          ],
          unit: [
            {
              required: true,
              message: '请选择',
              trigger: 'blur,change',
            },
          ],
          trafficApps: [{ validator: this.validateAppInputValue, trigger: 'blur' }],
          blackTrafficApps: [{ validator: this.validateAppInputValue, trigger: 'blur' }],
          trafficAdx: [{ validator: this.validateAppInputValue, trigger: 'blur' }],
        },
        rules2: {
          country: [{ required: true, message: 'country不能位空', trigger: 'blur' }],
          os: [{ required: true, message: 'os不能为空', trigger: 'blur' }],
          promotionApp: [{ required: true, message: 'promotionApp不能为空', trigger: 'blur' }],
          minDeviceCount: [{ required: true, message: 'minDeviceCount不能为空', trigger: 'blur' }],
          expCvr: [{ required: true, message: 'expCvr不能为空', trigger: 'blur' }],
        },
      };
    },
    created() {},
    mounted() {
      this.getCountry();
      this.getAdxList();
      this.getPromotionApp();
    },
    methods: {
      validateAppInputValue(rule, value, callback) {
        const regex = /[\u00A0\u3000\u0020\uFF0C\r\n]/; // 不允许输入中文逗号和空格
        if (regex.test(value)) {
          console.log('不允许输入中文逗号和空格以及换行回车');
          callback(new Error('不允许输入中文逗号和空格以及换行回车'));
        } else {
          callback();
        }
      },
      validateIntegerValue(rule, value, callback) {
        const integerValue = parseInt(value, 10);
        if (Number.isInteger(integerValue) && integerValue >= 1 && integerValue <= 60) {
          callback();
        } else {
          callback(new Error('请输入1到60的整数'));
        }
      },

      // 国家
      getCountry() {
        fetchCountryList().then((res) => {
          this.options.country = res.result;
          this.postData.country = res.result[0].countryCode;
        });
      },
      //查询adx列表
      getAdxList() {
        fetchAdxList1().then((res) => {
          this.adxOptions = res.result;
        });
      },

      // app
      getPromotionApp() {
        fetchPromotionAppList({ platforms: this.postData.os }).then((res) => {
          this.options.promotionApp = res.result;
        });
      },
      getPromotionApp2() {
        fetchPromotionAppList({ platforms: this.filter.os }).then((res) => {
          this.options.promotionApp = res.result;
        });
      },
      openAppCvrSearchDialog() {
        this.appCvrSearchForm = true;
        this.filter.country = this.postData.country;
        this.filter.os = this.postData.os;
        this.filter.promotionApp = this.postData.promotionApp;
      },

      searchAppReportData(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading1.btnSearchAppReport = true;
          let filterData = JSON.parse(JSON.stringify(this.filter));
          if (this.postData.dayCount) {
            filterData.dayCount = this.postData.dayCount;
          }
          console.log('==============' + filterData);
          fetchAppReportData(filterData)
            .then((res) => {
              if (res.code == 200) {
                console.log(res);
                this.appReportData = res.result;
              } else {
                this.$message.error(res.message);
              }
              this.loading1.btnSearchAppReport = false;
            })
            .catch((e) => {
              this.$message.error(e);
              this.loading1.btnSearchAppReport = false;
            });
        });
      },

      applyCheckApp() {
        let appsStr = '';
        if (this.appReportData) {
          this.appReportData.forEach((item) => {
            console.log(item);
            console.log(item.appId);
            appsStr = appsStr + item.appId + ',';
          });
        }
        appsStr = appsStr.substring(0, appsStr.length - 1);
        this.postData.trafficApps = appsStr;
        console.log(appsStr);
        this.postData.promotionApp = this.filter.promotionApp;
        this.postData.os = this.filter.os;
        this.getPromotionApp();
        this.postData.country = this.filter.country;

        let minDeviceCount = this.filter.minDeviceCount;
        let expCvr = this.filter.expCvr;
        this.postData.minDeviceCount = minDeviceCount;
        this.postData.expCvr = expCvr;

        this.appCvrSearchForm = false;
      },

      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading2.btn = true;
          let postDataFinal = Object.assign({}, this.postData);
          if (this.postData.catWhite) {
            postDataFinal.catWhite = this.postData.catWhite.toString();
          }
          if (this.postData.trafficAdx) {
            postDataFinal.trafficAdx = this.postData.trafficAdx.toString();
          }
          if (this.postData.convEvent) {
            postDataFinal.convEvent = this.postData.convEvent.toString();
          }
          addHoloUpload(postDataFinal)
            .then((res) => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  message: '添加上传成功',
                  type: 'success',
                });
                this.$router.push({
                  path: '/click/holo/upload/list',
                });
              } else {
                this.$message.error(res.message);
              }
              this.loading2.btn = false;
            })
            .catch((e) => {
              this.$message.error(e);
              this.loading2.btn = false;
            });
        });
      },

      searchCount(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading2.btn = true;
          let postDataFinal = Object.assign({}, this.postData);
          if (this.postData.catWhite) {
            postDataFinal.catWhite = this.postData.catWhite.toString();
          }
          if (this.postData.trafficAdx) {
            postDataFinal.trafficAdx = this.postData.trafficAdx.toString();
          }
          if (this.postData.convEvent) {
            postDataFinal.convEvent = this.postData.convEvent.toString();
          }
          searchCount(postDataFinal)
            .then((res) => {
              console.log(res);
              if (res.code == 200) {
                this.$alert(res.result, '设备数量预估', {
                  confirmButtonText: '确定',
                });
              } else {
                this.$message.error(res.message);
              }
              this.loading2.btn = false;
            })
            .catch((e) => {
              this.$message.error(e);
              this.loading2.btn = false;
            });
        });
      },

      cancelAppCheck() {
        //清空弹框中的值
        this.filter.promotionApp = '';
        this.filter.expCvr = null;
        this.filter.minDeviceCount = null;
        this.appCvrSearchForm = false;
      },

      closeDialog() {
        this.cancelAppCheck();
      },

      goOff() {
        this.$router.push({
          path: '/click/holo/upload/list',
        });
      },
    },
  };
</script>

<style></style>
